*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  --bubble-gum-pink: #ff5ab7;
  --black: #1e1e1e;
  --cool-blue: #4386bf;
  --pinkish-grey: #c7c7c7;
  --pale-grey: #f5f6fa;
  --warm-grey: #888;
  --white: #fff;
  --very-light-pink: #c4c4c4;
  --medium-blue: #307bbe;
  --black-two: #121212;
  --azure: #0290ff;
  --white-two: #e8e8e8;
  --white-three: #fafafa;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
form {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style-type: none;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'RobotoSlab Bold';
  src: url(./fonts/RobotoSlab-Bold.ttf);
}

@font-face {
  font-family: 'RobotoSlab Light';
  src: url(./fonts/RobotoSlab-Light.ttf);
}

nav {
  display: flex;
  align-items: center;
  margin: 1.813rem 6.25rem;
  gap: 2.938rem;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 2.563rem;
}

.brand {
  font-family: 'Montserrat', serif;
  font-weight: bold;
  color: var(--azure);
  font-size: 1.875rem;
}

.nav-link {
  font-family: 'Montserrat', serif;
  font-weight: normal;
  font-size: 0.813rem;
  color: var(--black-two);
  text-decoration: none;
  letter-spacing: 1.9px;
}

.line {
  border: 1px solid var(--white-two);
}

.books-line {
  margin: 0 6.25rem;
}

.books-section,
.form-section,
.categories-section {
  margin: 1.813rem 6.25rem;
}

.categories-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5em;
  justify-content: center;
}

.book {
  display: flex;
  border: 1px solid var(--white-two);
  background-color: var(--white);
  padding: 2rem 1.688rem 1.75rem 1.688rem;
  margin-bottom: 0.938rem;
}

.book > * {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.book > *:first-child {
  flex-basis: 40%;
}

.book-category {
  font-family: 'Montserrat', serif;
  opacity: 0.5;
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--black-two);
}

.book-title {
  font-family: 'RobotoSlab Bold', serif;
  font-size: 1.375rem;
  font-weight: bold;
  color: var(--black-two);
}

.book-actions {
  margin-top: 1.313rem;
  display: flex;
}

.book-author,
.book-actions button {
  font-family: 'RobotoSlab Light', serif;
  font-size: 0.875rem;
  font-weight: 300;
  color: var(--cool-blue);
}

.book-actions button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.book-actions button:not(:last-child)::after {
  content: '|';
  margin: 0 0.938rem;
  line-height: 1.5rem;
  color: var(--white-two);
}

.book-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progress-stats {
  display: flex;
  flex-direction: column;
  margin-left: 1.313rem;
}

.percentage {
  font-family: 'Montserrat', serif;
  font-size: 2rem;
  font-weight: normal;
  color: var(--black-two);
}

.percentage-completed {
  opacity: 0.5;
  font-family: 'Montserrat', serif;
  font-size: 0.875rem;
  font-weight: normal;
  color: var(--black-two);
}

.current-chapter {
  opacity: 0.5;
  font-family: 'RobotoSlab Light', serif;
  font-size: 0.813rem;
  font-weight: 300;
  color: var(--black-two);
}

.chapter {
  font-family: 'RobotoSlab Light', serif;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: -0.4px;
  color: var(--black-two);
}

.chapter-info {
  border-left: solid 1px var(--white-two);
  padding-left: 4%;
  margin-left: -4%;
}

.update-progress {
  padding: 0.438rem 1.188rem 0.5rem 1.375rem;
  border-radius: 3px;
  background-color: var(--azure);
  font-family: 'RobotoSlab Light', serif;
  font-size: 0.813rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--white);
  border: none;
  margin-top: 1.438rem;
  width: 11.5rem;
}

.form {
  flex-direction: column;
}

.form form {
  display: flex;
  flex-direction: row;
}

.add-book-title {
  font-family: 'Montserrat', serif;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: -0.18px;
  color: var(--warm-grey);
  margin-bottom: 1.888rem;
}

.form input {
  height: 2.813rem;
  border-radius: 4px;
  border: solid 1px var(--white-two);
  background-color: var(--white);
  width: 40%;
  margin-right: 2.125rem;
  padding: 0 1.063rem;
}

.form input::placeholder {
  font-family: 'Montserrat', serif;
  font-size: 1rem;
  letter-spacing: -0.15px;
  color: var(--very-light-pink);
}

.add-book-btn {
  width: 11.5rem;
  height: 2.813rem;
  border-radius: 3px;
  border: none;
  background-color: var(--azure);
  font-family: 'RobotoSlab Bold', serif;
  font-size: 0.813rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--white);
  cursor: pointer;
}
